import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { throttle } from 'lodash'
import SVG from 'react-inlinesvg'
import {
  AttachMoney, 
  AllInclusive, 
  Panorama, 
  ViewQuilt, 
  Store, 
  ShortText, 
  Map, 
  Place, 
  Add, 
  SettingsInputComponent,
  Tv
} from '@material-ui/icons'

import Theme from '../theme'
import TemporaryDrawer from './temporaryDrawer'
import logo from '../images/logo.svg'
import Dropdown from './dropdown/dropdown'
import 'typeface-roboto'

const HeaderWrapper = styled.div`
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${Theme.palette.primary.dark};
  z-index: 99999999999;
  box-shadow: 0px 0px 10px -2px black;
  position: fixed;

  @media(max-width: 768px) {
    height: 56px;
  }
`

const Logo = styled.div`
  width: 80px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  background: transparent;
  padding: 10px;

  @media(max-width: 768px) {
    width: 46px;
  }
`

const LogoTextWrapper = styled.div`
  margin: 0 20px;
  height: 100%;
`

const SVGWrapper = styled(SVG)`
  width: 50px;
  fill: white;

  @media(max-width: 770px) {
    width: 35px;
  }
`

type State = {
  isMobile: boolean,
  isServicesDropdownOpen: boolean
}

type Props = {}

export default class Header extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.resize = throttle(this.resize.bind(this), 200)
    // this.handleHover = this.handleHover.bind(this)
    // this.handleClose = this.handleClose.bind(this)

    this.state = {
      isMobile: false,
      isServicesDropdownOpen: false
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize() {
    if (window.innerWidth <= 768) {
      this.setState({
        isMobile: true
      })
    } else {
      this.setState({
        isMobile: false
      })
    }
  }

  // handleHover = event => {
  //   this.setState({
  //     isServicesDropdownOpen: true,
  //     menuElement: event.currentTarget
  //   })
  // }

  // handleClose = () => {
  //   this.setState({
  //     isServicesDropdownOpen: false,
  //     menuElement: null
  //   })
  // }

  render() {    
    const navItems = [
      {
        id: 'nav-space',
        name: 'Space',
        href: '/space',
        icon: <ViewQuilt style={{ color: Theme.palette.primary.main }}/>,
        nestedItems: []
      },
      {
        id: 'nav-services',
        name: 'Services', 
        href: '/services',
        icon: <AllInclusive style={{ color: Theme.palette.primary.main }}/>, 
        nestedItems: [
          {
            id: 'nav-services-add-ons',
            name: 'Add On Services',
            href: '/services#add-on-services',
            icon: <Add style={{ color: Theme.palette.primary.main }}/>
          },
          {
            id: 'nav-services-equipment',
            name: 'Equipment',
            href: '/services#equipment',
            icon: <SettingsInputComponent style={{ color: Theme.palette.primary.main }}/>
          },
          {
            id: 'nav-services-led-screens',
            name: 'LED Screens',
            href: '/services#led-screens',
            icon: <Tv style={{ color: Theme.palette.primary.main }}/>
          }
        ]
      }, 
      {
        id: 'nav-rates',
        name: 'Rates',
        href: '/rates',
        icon: <AttachMoney style={{ color: Theme.palette.primary.main }}/>,
        nestedItems: [],
      },
      {
        id: 'nav-gallery',
        name: 'Gallery',
        href: '/gallery',
        icon: <Panorama style={{ color: Theme.palette.primary.main }}/>,
        nestedItems: []
      },
      {
        id: 'nav-about',
        name: 'About', 
        href: '/about',
        icon: <ShortText style={{ color: Theme.palette.primary.main }}/>,
        nestedItems: [
          {
            id: 'nav-about-venue',
            name: 'The Venue',
            href: '/about#venue',
            icon: <Store style={{ color: Theme.palette.primary.main }}/>
          },
          {
            id: 'nav-about-brochure',
            name: 'Brochure',
            href: '/about#brochure',
            icon: <Place style={{ color: Theme.palette.primary.main }}/>
          },
          {
            id: 'nav-about-map',
            name: 'Map',
            href: '/about#map',
            icon: <Map style={{ color: Theme.palette.primary.main }}/>
          }
        ]
      }
    ]

    const navigation = this.state.isMobile ? (
      <TemporaryDrawer items={navItems} />
    ) : (
      <Dropdown items={navItems} />
    )

    return (
      <HeaderWrapper>
        <LogoTextWrapper>
          <Logo>
          <Link
            to="/"
            style={{
              width: 'inherit',
              color: 'white',
              textDecoration: 'none',
            }}>
            <SVGWrapper src={logo}>
              <img id="nav-logo" src={logo} alt="Logo" style={{margin: 0, fill: 'white'}}/>
            </SVGWrapper>
          </Link>
        </Logo>
        </LogoTextWrapper>
        {navigation}
      </HeaderWrapper> 
    )
  }
}