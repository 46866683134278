import React, { SFC } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import SVG from 'react-inlinesvg'

import theme from '../theme'
import logo from '../images/logo.svg'
import InquireServices from './inquireService'
import { Business } from '../utils/constants'

const MapPin = styled(SVG)` 
    width: 50px;
    fill: ${theme.palette.primary.main}
`

const Wrapper = styled.div`
    width: 100%;    
`

const MapWrapper = styled.div`
    height: 350px;
    width: 100%;
    box-shadow: 0px -2px 8px -4px black;
`

const SocialBar = styled.div`
    background: #555555;
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: space-around;
    align-items: center;
`

const InfoAreaWrapper = styled.div`
    background: #4E4E4E;
    padding: 40px 40px 20px 40px;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const InfoArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 90vw;
    margin: 0 auto 40px auto;

    @media(max-width: 568px) {
        flex-direction: column;
    }
`

const ContactArea = styled.div<{margin: string}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${props => props.margin ? props.margin : 'initial'};

    @media(max-width: 568px) {
        margin: 0 0 20px 0;

        > div {
            margin-bottom: 20px;
        }
    }
`

const ContactAreaSection = styled.div`
    display: flex;

    > svg {
        margin-right: 20px;
    }
`

const ContactAreaLink = styled.a`
    color: white;

    :hover {
        color: #D4D4D4;
        text-decoration: none;
    }
`

const InfoText = styled.p`
    font-family: 'roboto';
    font-size: 12px;
    margin: 0;
`

const SectionTitle = styled.p`
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 15px;
    grid-area: 'header';
`

const HoursGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: 
        "header header"
        "mon-d mon-t"
        "tue-d tue-t"
        "wed-d wed-t"
        "thu-d thu-t"
        "fri-d fri-t"
        "sat-d sat-t"
        "sun-d sun-t";
    font-size: 12px;
    font-weight: 100;

    > p {
        &:first-child {
            margin: 0 0 10px 0;
        }

        margin: 0 0 5px 0;
        color: white;
    }

    @media(max-width: 445px) {
        width: 75%;
        margin: 40px auto 0 auto;
    }
`

const MondayDay = styled.p`
    grid-area: mon-d
`

const MondayTime = styled.p`
    grid-area: mon-t
`

const TuesdayDay = styled.p`
    grid-area: tue-d
`

const TuesdayTime = styled.p`
    grid-area: tue-t
`

const WednesdayDay = styled.p`
    grid-area: wed-d
`

const WednesdayTime = styled.p`
    grid-area: wed-t
`

const ThursdayDay = styled.p`
    grid-area: thu-d
`

const ThursdayTime = styled.p`
    grid-area: thu-t
`

const FridayDay = styled.p`
    grid-area: fri-d
`

const FridayTime = styled.p`
    grid-area: fri-t
`

const SaturdayDay = styled.p`
    grid-area: sat-d
`

const SaturdayTime = styled.p`
    grid-area: sat-t
`

const SundayDay = styled.p`
    grid-area: sun-d
`

const SundayTime = styled.p`
    grid-area: sun-t
`

const PrivacyPolicy = styled.a`
    color: white;
    width: fit-content;
    font-size: 10px;
    margin: 0 auto 10px auto;
    text-decoration: underline;

    &:hover {
        color: rgba(255,255,255,0.8);
    }
`

const Copyright = styled.p`
    color: white;
    font-size: 12px;
    font-weight: lighter;
    text-align: center;
    margin: 0;
`

const Footer: SFC = () => (
    <Wrapper>
        <InquireServices />
        <MapWrapper>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key:process.env.GOOGLE_MAPS_API_KEY
                }}
                defaultCenter={{
                    lat: 34.1019800,
                    lng: -118.3406078
                }}
                defaultZoom={18}>
                <MapPin src={logo} lat={34.1019800} lng={-118.3406078}/>
            </GoogleMapReact>
        </MapWrapper>
        <SocialBar>
            <a href={Business.facebook} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon 
                    icon={faFacebookF} 
                    color={theme.palette.primary.light}
                    style={{width: '23px', height: '23px'}}/>
            </a>
            <a href={Business.twitter} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon 
                    icon={faTwitter} 
                    color={theme.palette.primary.light}
                    style={{width: '25px', height: '25px'}}/>
            </a>
            <a href={Business.instagram} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon 
                    icon={faInstagram} 
                    color={theme.palette.primary.light}
                    style={{width: '25px', height: '25px'}}/>
            </a>
            <a href={Business.youtube} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon 
                    icon={faYoutube} 
                    color={theme.palette.primary.light}
                    style={{width: '25px', height: '22px'}}/>
            </a>
        </SocialBar>
        <InfoAreaWrapper>
            <InfoArea>
                <ContactArea margin="0 100px 0 0">
                    <ContactAreaSection>
                        <FontAwesomeIcon icon={faMapMarkerAlt} color="white" style={{width: '18px', height: '18px'}}/>
                        <ContactAreaLink href="https://goo.gl/maps/ssjtxkMuv692" target="_blank" rel="noopener noreferrer">
                            <InfoText>6801 Hollywood Blvd</InfoText>
                            <InfoText>Ste 200</InfoText>
                            <InfoText>{Business.city}, {Business.state} {Business.zip}</InfoText>
                            <InfoText>United States</InfoText>
                        </ContactAreaLink>
                    </ContactAreaSection>
                    <ContactAreaSection>
                        <FontAwesomeIcon icon={faPhone} color="white" flip="horizontal"/>
                        <ContactAreaLink href="tel:+13234985255">
                            <InfoText>{Business.phoneNumber}</InfoText>
                        </ContactAreaLink>
                    </ContactAreaSection>
                    <ContactAreaSection>
                        <FontAwesomeIcon icon={faEnvelope} color="white"/>
                        <ContactAreaLink href={`mail:${Business.email}`}>
                            <InfoText>{Business.email}</InfoText>
                        </ContactAreaLink>
                    </ContactAreaSection>
                </ContactArea>
                <HoursGrid>
                    <SectionTitle>Hours</SectionTitle>
                    <MondayDay>Mon</MondayDay>
                    <MondayTime>9:00 AM - 5:00 PM</MondayTime>
                    <TuesdayDay>Tue</TuesdayDay>
                    <TuesdayTime>9:00 AM - 5:00 PM</TuesdayTime>
                    <WednesdayDay>Wed</WednesdayDay>
                    <WednesdayTime>9:00 AM - 5:00 PM</WednesdayTime>
                    <ThursdayDay>Thu</ThursdayDay>
                    <ThursdayTime>9:00 AM - 5:00 PM</ThursdayTime>
                    <FridayDay>Fri</FridayDay>
                    <FridayTime>9:00 AM - 5:00 PM</FridayTime>
                    <SaturdayDay>Sat</SaturdayDay>
                    <SaturdayTime>Closed</SaturdayTime>
                    <SundayDay>Sun</SundayDay>
                    <SundayTime>Closed</SundayTime>
                </HoursGrid>
            </InfoArea>
            <Copyright>Copyright © {new Date().getFullYear()} The Venue of Hollywood. All Rights Reserved.</Copyright>
        </InfoAreaWrapper>
    </Wrapper>
)

export default Footer