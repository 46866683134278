import React, { ReactNode } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { WindowLocation } from '@reach/router'

import Header from './header'
import Footer from './footer'
import Theme from '../theme'
import LogoWithText from '../images/venue-of-hollywood-logo-with-text.png'
import { Business } from '../utils/constants'

import './layout.css'
import 'bootstrap/dist/css/bootstrap.css'

const Main = styled.div`
  background: white;
  margin-top: 100px;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;

  @media(max-width: 768px) {
    margin-top: 56px;
  }
`

type Props = {
  location: WindowLocation,
  children: ReactNode
}

export default (props: Props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data: any): ReactNode => (
      <>
        <MuiThemeProvider theme={Theme}>
          <Header/>
          <Main>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Located in the heart of Hollywood, at the prestigious Hollywood & Highland Center, the prime location of The Venue at SM Hollywood offers a unique and versatile event space for the A-list clientele of Hollywood.' },
                { name: 'keywords', content: 'hollywood, venue, party, the venue, the venue of hollywood' },
                { name: 'content-type', content: 'text/html, charset=utf-8'},
                { name: 'viewport', content: 'width=device-width, initial-scale=1'}
              ]}
              link={[
                { href: 'https://fonts.googleapis.com/icon?family=Material+Icons', rel: 'stylesheet' },
                { href: 'https://cdn.jsdelivr.net/npm/photo-sphere-viewer@3.4.1/dist/photo-sphere-viewer.min.css', rel: 'stylesheet'},
                { 'href': 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css', 'rel':'stylesheet', 'charset': 'UTF-8' },
                { 'href': 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css', 'rel':'stylesheet', 'charset': 'UTF-8' }
              ]}
              script={[
                { src: 'https://www.google.com/recaptcha/api.js', type: 'text/javascript' }
              ]}>
              <script type="application/ld+json">{`{
                "@context": "http://schema.org",
                "@type": "Organization",
                "url": ${Business.url},
                "logo": ${LogoWithText},
                "name": ${Business.name},
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": ${Business.phoneNumber},
                  "contactType": "Customer service"
                },
                "sameAs": [
                  ${Business.facebook},
                  ${Business.instagram},
                  ${Business.instagram},
                  ${Business.youtube}
                ]
              }`}</script>
            </Helmet>
          {props.children}
          </Main>
          <Footer />
          <div id="modal-root" />
        </MuiThemeProvider>
      </>
    )}
  />
)