export const Business = Object.freeze({
    name: 'The Venue of Hollywood',
    address: '6801 Hollywood Blvd Ste 200',
    city: 'Hollywood',
    state: 'CA',
    zip: '90028',
    phoneNumber: '+1 (323) 498-5255',
    email: 'info@thevenueofhollywood.com',
    url: 'https://www.thevenueofhollywood.com',
    facebook: 'https://www.facebook.com/The-Venue-Of-Hollywood-467495180374514',
    twitter: 'https://twitter.com/VenueofHW',
    instagram: 'https://www.instagram.com/venueofhollywood',
    youtube: 'https://www.youtube.com/channel/UCHT8S4OYUlgFSoDVHV8EX2w'
})